:root {
	--black: #0c0d0d;
	--reed: #f24f2f;
	--blue: #0003ff;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--black);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
}

html {
	scroll-snap-type: y proximity;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	text-decoration: none !important;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 27, 2020 */



@font-face {
	font-family: 'wotfardbold';
	src: url('./Fonts/wotfard/wotfard-bold-webfont.eot');
	src: url('./Fonts/wotfard/wotfard-bold-webfont.eot?#iefix') format('embedded-opentype'),
		url('./Fonts/wotfard/wotfard-bold-webfont.woff2') format('woff2'),
		url('./Fonts/wotfard/wotfard-bold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'wotfardextralight';
	src: url('./Fonts/wotfard/wotfard-extralight-webfont.eot');
	src: url('./Fonts/wotfard/wotfard-extralight-webfont.eot?#iefix') format('embedded-opentype'),
		url('./Fonts/wotfard/wotfard-extralight-webfont.woff2') format('woff2'),
		url('./Fonts/wotfard/wotfard-extralight-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'wotfardlight';
	src: url('./Fonts/wotfard/wotfard-light-webfont.eot');
	src: url('./Fonts/wotfard/wotfard-light-webfont.eot?#iefix') format('embedded-opentype'),
		url('./Fonts/wotfard/wotfard-light-webfont.woff2') format('woff2'),
		url('./Fonts/wotfard/wotfard-light-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'wotfardmedium';
	src: url('./Fonts/wotfard/wotfard-medium-webfont.eot');
	src: url('./Fonts/wotfard/wotfard-medium-webfont.eot?#iefix') format('embedded-opentype'),
		url('./Fonts/wotfard/wotfard-medium-webfont.woff2') format('woff2'),
		url('./Fonts/wotfard/wotfard-medium-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'wotfardregular';
	src: url('./Fonts/wotfard/wotfard-regular-webfont.eot');
	src: url('./Fonts/wotfard/wotfard-regular-webfont.eot?#iefix') format('embedded-opentype'),
		url('./Fonts/wotfard/wotfard-regular-webfont.woff2') format('woff2'),
		url('./Fonts/wotfard/wotfard-regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'wotfardsemibold';
	src: url('./Fonts/wotfard/wotfard-semibold-webfont.eot');
	src: url('./Fonts/wotfard/wotfard-semibold-webfont.eot?#iefix') format('embedded-opentype'),
		url('./Fonts/wotfard/wotfard-semibold-webfont.woff2') format('woff2'),
		url('./Fonts/wotfard/wotfard-semibold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'wotfardthin';
	src: url('./Fonts/wotfard/wotfard-thin-webfont.eot');
	src: url('./Fonts/wotfard/wotfard-thin-webfont.eot?#iefix') format('embedded-opentype'),
		url('./Fonts/wotfard/wotfard-thin-webfont.woff2') format('woff2'),
		url('./Fonts/wotfard/wotfard-thin-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}